import React, { useEffect, useState } from "react";
import cssClasses from "./FuelsChips.module.css";

type Props = {
    filterChips: string[];
    handleClick: (selectedChip: string)=>void;
};

/**
 * First one will be default selected
 * 
 * @param param0 
 * @returns 
 */

function FuelsChips({filterChips, handleClick}: Props) {
  const [selectedChip, setSelectedChip] = useState(filterChips[0]);

  const handleClickOnChip = (chip: string) => {
    setSelectedChip(chip);
  };

  useEffect(()=>{
    handleClick(selectedChip);
  }, [selectedChip])

  return (
    <div className={cssClasses.chipsContainer}>
      {filterChips.map((chip) => {
        return (
          <div
            className={selectedChip === chip ? cssClasses.chipSelected : cssClasses.chip}
            onClick={() => handleClickOnChip(chip)}
            key={chip}
          >
            {/* {selectedChip === chip ? (
              <div className={cssClasses.hignlighter}></div>
            ) : null} */}
            {chip}
          </div>
        );
      })}
    </div>
  );
}

export default FuelsChips;
