// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+yX-Eu\\+vhmSaMRjebhwU-w\\=\\= {\n  position: relative;\n  width: 100%;\n  padding: 10px 0px 10px 0px;\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  height: 86vh;\n}\n.f1axIg\\+mlP4TjembV9SvhA\\=\\= {\n  display: flex;\n  flex-direction: row;\n  margin: 10px 0 10px 0;\n  justify-content: space-between;\n}\n.Fy\\+WkIjNUS-B2oFwJS2ISQ\\=\\= {\n  display: flex;\n  flex-direction: row;\n}\n\n.QETW0h9CmYvkq7FfJkwqIA\\=\\= {\n  border-radius: 10px;\n  background: linear-gradient(\n    45deg,\n    rgb(217, 206, 255) 0%,\n    rgb(196, 246, 255) 100%\n  );\n  background: linear-gradient(45deg, #dfd9fa 0%, rgb(187, 230, 232) 100%);\n  border-radius: 5px;\n  padding: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/dimentionalComponents/general/aggregateComponent/AggregateComponent.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,0BAA0B;EAC1B,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB;;;;GAIC;EACD,uEAAuE;EACvE,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".aggregateContainer {\n  position: relative;\n  width: 100%;\n  padding: 10px 0px 10px 0px;\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  height: 86vh;\n}\n.firstRow {\n  display: flex;\n  flex-direction: row;\n  margin: 10px 0 10px 0;\n  justify-content: space-between;\n}\n.secondRow {\n  display: flex;\n  flex-direction: row;\n}\n\n.pieContainer {\n  border-radius: 10px;\n  background: linear-gradient(\n    45deg,\n    rgb(217, 206, 255) 0%,\n    rgb(196, 246, 255) 100%\n  );\n  background: linear-gradient(45deg, #dfd9fa 0%, rgb(187, 230, 232) 100%);\n  border-radius: 5px;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aggregateContainer": "+yX-Eu+vhmSaMRjebhwU-w==",
	"firstRow": "f1axIg+mlP4TjembV9SvhA==",
	"secondRow": "Fy+WkIjNUS-B2oFwJS2ISQ==",
	"pieContainer": "QETW0h9CmYvkq7FfJkwqIA=="
};
export default ___CSS_LOADER_EXPORT___;
