import AES from "crypto-js/aes";
import CryptoJS from "crypto-js";

export const GEO_LOCATION_ES_URL =
  "https://7ep-dev-api.7-eleven.com/fuels/pricing/elasticsearch/geolocation/_search/?source_content_type=application/json";
export const PRICING_ERROR_ES_URL =
  "https://7ep-dev-api.7-eleven.com/fuels/pricing/elasticsearch/pricingerror/_search/?source_content_type=application/json";
export const ES_AUTH_HEADER = {
  Authorization: AES.decrypt(
    "U2FsdGVkX1+2yR0Xcq4lN7Ba0ru+h0RE+nhmZJkD8W18OERl3DE3SRNfg1eQ2LhjntJ7O6fUe0KxxjnGsM/TKS3L9AwuZ0cZYLiyP6R8xxk=",
    "FCC"
  ).toString(CryptoJS.enc.Utf8),
  "X-Subscription-Key": AES.decrypt(
    "U2FsdGVkX18TJPu1GMC1iCdE13D5Q9harKGcqUXBuIBWVRfnxMkiPd+kWseswyqEiQPWFZ8b0lxSg+GGSkTgqA==",
    "FCC"
  ).toString(CryptoJS.enc.Utf8),
  "Content-Type": "application/json",
};
export const ES_AUTH_HEADER_TEST = {
  Authorization: "Basic ZWxhc3RpYzpZTTNES2MwazE1OTdiR2o0ODhlSjFzWFA=",
  "X-Subscription-Key": "2bmrAuJKcMWA2zXf4cSghchQ4UonUqDn",
  "Content-Type": "application/json",
};
export const ES_AUTH_STORE_DETAILS_HEADER = {
  authorization: AES.decrypt(
    "U2FsdGVkX1+2yR0Xcq4lN7Ba0ru+h0RE+nhmZJkD8W18OERl3DE3SRNfg1eQ2LhjntJ7O6fUe0KxxjnGsM/TKS3L9AwuZ0cZYLiyP6R8xxk=",
    "FCC"
  ).toString(CryptoJS.enc.Utf8),
  "refresh_token": "WKx71QqVW0mgNCYzzmpL3CUDROt3Bfwl9xGWogo34J",
  "X-Subscription-Key": AES.decrypt(
    "U2FsdGVkX18TJPu1GMC1iCdE13D5Q9harKGcqUXBuIBWVRfnxMkiPd+kWseswyqEiQPWFZ8b0lxSg+GGSkTgqA==",
    "FCC"
  ).toString(CryptoJS.enc.Utf8),
  "Content-Type": "application/json",
};
