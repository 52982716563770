import MySitesComponent from "./components/mySitesComponent/MySitesComponent";
import { useEffect, useState } from "react";
import FuelsChips from "./components/fuelsChips/FuelsChips";
import ProfileContainerComponent from "./components/profileContainerComponent/ProfileContainerComponent";
import FuelsResponsiveBar from "./components/Bar/FuelsResponsiveBar";
import {
  getDetailsOfListOfStores, getUserPrefenceByUserId,
} from "./services/externalCalls";
import General from "./components/dimentionalComponents/general/General";
import {
  ElasticSearchResult,
} from "./dataDefinition/elasticSearchTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./reduxComponents/store";
import { populateSources } from "./reduxComponents/slices/mySitesSourcesSlice";
import MapContainer from "./components/mapContainer/MapContainer";

const dimention = ["General", "Price Update", "Transaction fill"];

type Props = {};

function App({}: Props) {
  const [activeDimention, setActiveDimetion] = useState(dimention[1]);
  // const [mySitesSources, setMySitesSources] = useState<Source[]>(null);
  const mySitesSources = useSelector((state: RootState)=>state.mySitesSources);
  const dispatch = useDispatch();
  function handleChipClick(dName: string) {
    console.log(dName);
    setActiveDimetion(dimention.filter((d) => d === dName)[0]);
  }

  const selectedImportCode = useSelector((s: RootState) => s.selectedImportCode);

  useEffect(() => {
    getUserPrefenceByUserId("cgup3002").then((res)=>{
      console.log(res.data)
      getDetailsOfListOfStores(res.data).then(  //dev sites
      (data: ElasticSearchResult) => {
        dispatch(populateSources(data.hits.hits.map((x) => x._source)))
      }
    );
    })
  }, []);

  useEffect(() => {
    console.log("global state",mySitesSources);
  }, [mySitesSources])

  return (
    <div className="rootContainer">
      <div className="profileCtaContainer">
        <MapContainer/>
        <ProfileContainerComponent userName={"Anirban"} />
      </div>
      <div className="mysitesContainer">
        {/* mysites container */}
        <MySitesComponent mySitesSources={mySitesSources} />
      </div>
      <div className="mainViewContainer">
        <FuelsChips
          filterChips={dimention.map((d) => d)}
          handleClick={(dn) => handleChipClick(dn)}
        />
        <div>
          {activeDimention === "General" && <General importCode={selectedImportCode}/>}
          {activeDimention === "Price Update" && <FuelsResponsiveBar />}
          {activeDimention === "Transaction fill" && <div>Transaction</div>}
          {/* <FuelsResponsivePie /> */}
        </div>
      </div>
      <div id="popUpContainer"></div>
    </div>
  );
}

export default App;
