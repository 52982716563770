import ReactECharts from "echarts-for-react";
import { PieData } from "../../../dataDefinition/chartDataTypes";
import cssClasses from './FuelsPie.module.css'
type Props = {
  chartName: string;
  description?: string;
  tooltipDataDescription: string;
  pieData: PieData[];
  tooltipFormatter: (param: any) => string;
  legendFormatter: (name: any) => string;
  lebelFormatter: (param: any) => string;
};

function FuelsPie({
  chartName,
  description,
  tooltipDataDescription,
  pieData,
  tooltipFormatter,
  legendFormatter,
  lebelFormatter,
}: Props) {
  const option = {
    title: {
      text: chartName,
      subtext: description,
      left: "center",
    },
    tooltip: {
      trigger: "item",
      formatter: tooltipFormatter,
    },
    legend: {
      orient: "vertical",
      left: "left",
      formatter: legendFormatter,
    },
    series: [
      {
        name: tooltipDataDescription,
        type: "pie",
        radius: ["25%", "60%"],
        data: pieData,
        // emphasis: {
        //   itemStyle: {
        //     shadowBlur: 10,
        //     shadowOffsetX: 0,
        //     shadowColor: 'rgba(0, 0, 0, 0.5)'
        //   }
        // },
        label: {
          show: true,
          position: "inner",
          formatter: lebelFormatter,
        },
      },
    ],
  };

  //   echarts.use(
  //     [TitleComponent, TooltipComponent, GridComponent, PieChart, SVGRenderer]
  //   );

  return (
    <div className={cssClasses.container}>
      <ReactECharts
        option={option}
        style={{
          height: "350px",
          width: "450px",
        }}
        opts={{ renderer: "svg" }}
      />
    </div>
  );
}

export default FuelsPie;
