import React from "react";
import { PriceDetails } from "../../../../../dataDefinition/getPricesTypes";
import cssClasses from "./PricesComponent.module.css";
type Props = {
  priceDetails: PriceDetails;
};

function PricesComponent({ priceDetails }: Props) {
  return (
    <div className={cssClasses.container}>
      <h3>Prices</h3>
      <div className={cssClasses.singleSourcePricesContainer}>
        {priceDetails?.prices.map((p) => {
          return (
            <div className={cssClasses.onePriceContainer}>
              <h5>{p.productName}</h5>
              <p className={cssClasses.price}>{p.retail}</p>
              {p.effectiveDate.split(" ").map((e) => (
                <p key={e} className={cssClasses.dateTime}>
                  {e}
                </p>
              ))}
            </div>
          );
        })}
      </div>
      <div className={cssClasses.singleSourcePricesContainer}>
        {priceDetails?.opisPrices.map((p) => {
          return (
            <div className={cssClasses.onePriceContainer}>
              <h5>{p.productName}</h5>
              <p className={cssClasses.price}>{p.retail}</p>
              {p.effectiveDate.split(" ").map((e) => (
                <p key={e} className={cssClasses.dateTime}>
                  {e}
                </p>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PricesComponent;
