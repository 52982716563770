import { Wrapper } from "@googlemaps/react-wrapper";
import React from "react";
import { PieData } from "../../../../dataDefinition/chartDataTypes";
import { Source } from "../../../../dataDefinition/elasticSearchTypes";
import { StoreDetails } from "../../../../reduxComponents/slices/mySitesSourcesSlice";
import FuelsPie from "../../../fuelsEChats/fuelsPie/FuelsPie";
import MapComponent from "../../../mapComponent/MapComponent";
import cssClasses from "./AggregateComponent.module.css";
import PriceSign from "./priceSign/PriceSign";

type Props = {
  avgPerBrand: PieData[];
  mySitesSources: StoreDetails[];
};

function AggregateComponent({ avgPerBrand, mySitesSources }: Props) {
  return (
    <div className={cssClasses.aggregateContainer}>
      <div className={cssClasses.firstRow}>
        <PriceSign mySitesSources={mySitesSources} />
        <div>
          <h4>Gallons sold</h4>
          <p>
            Total:{" "}
            {mySitesSources
              ?.map((s) => s.pdisales["Regular-volume"])
              .reduce((x, y) => {
                return x + y;
              }, 0)}
          </p>
          <p>
            Average:{" "}
            {mySitesSources
              ?.map((s) => s.pdisales["Regular-volume"])
              .reduce((x, y) => {
                return x + y;
              }, 0) / mySitesSources?.length}
          </p>
        </div>
        <div>
          <h4>Profit</h4>
          <p>
            Total:{" "}
            {mySitesSources
              ?.map((s) => s.pdisales["Regular-grossProfit"])
              .reduce((x, y) => {
                return x + y;
              }, 0)}
          </p>
          <p>
            Average:{" "}
            {mySitesSources
              ?.map((s) => s.pdisales["Regular-grossProfit"])
              .reduce((x, y) => {
                return x + y;
              }, 0) / mySitesSources?.length}
          </p>
        </div>
      </div>
      <div className={cssClasses.secondRow}>
        <div className={cssClasses.pieContainer}>
          <FuelsPie
            chartName={"Average Price Per Brand"}
            tooltipDataDescription={"Average Price"}
            pieData={avgPerBrand}
            tooltipFormatter={function (params) {
              return `${params.name.split(",")[0]}<br />
                  (store count : ${params.data.value}) <br/>
                  $${params.name.split(",")[1]}`;
            }}
            legendFormatter={function (name) {
              return name.split(",")[0];
            }}
            lebelFormatter={function (params) {
              return `$${params.name.split(",")[1]}`;
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default AggregateComponent;
