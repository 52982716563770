export const DEFAULT_CONNECTION_INFO = {
    VERIFONE: {
      ipDnsName: "",
      username: "",
      password: "",
      protocol: "https",
      inBoundPath: "N/A",
      outBoundPath: "N/A",
      port: 443
    },
    GILBARCO: {
      ipDnsName: "",
      username: "PPXMLData",
      password: "L00k4MyD@ta",
      protocol: "ftp",
      inBoundPath: "/BOInBox",
      outBoundPath: "/PJR",
      port: 21
    },
    BULLOCH: {
      ipDnsName: "205.211.164.97",
      username: "7elevencontrol",
      password: "IPV4ANDIPV6",
      protocol: "sftp",
      inBoundPath: "",
      outBoundPath: "",
      port: 24
    },
    DEX: {
      ipDnsName: "N/A",
      username: "N/A",
      password: "N/A",
      protocol: "https",
      inBoundPath: "N/A",
      outBoundPath: "N/A",
      port: "443"
    }
  };
  