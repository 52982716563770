import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Source } from "../../../dataDefinition/elasticSearchTypes";
import { Connectivity } from "../../../dataDefinition/getConnectivityTypes";
import {
  StoreDetails,
  updateIsStoreConnectedByImportCode,
} from "../../../reduxComponents/slices/mySitesSourcesSlice";
import { getStoreConnectivity } from "../../../services/externalCalls";
import { DEFAULT_CONNECTION_INFO } from "../../../utils/constants";
import BrandBatch from "../../brandBatch/BrandBatch";
import cssClasses from "./SiteCard.module.css";

/**
 * TODO 1: add elipses in card, on click, it will open a small popup wich will contain the
 *         "remove site" action. the remove site will show a confirmation window.
 * TODO 2: a hamburger type icon can be placed to make the site grabbable and user can
 *         organize the sites(i.e: move a site up and down). For every sorting it will call
 *         the mongo db to persist the preference.
 */

type Props = {
  source: StoreDetails;
  isSelected: boolean;
  onClick: () => void;
};

function SiteCard({ source, isSelected, onClick }: Props) {
  const [showElipsePopUp, setShowElipsePopUp] = useState(false);

  const dispatch=useDispatch();

  function handleClickOnElipse(e: any) {
    e.stopPropagation();
    setShowElipsePopUp(!showElipsePopUp);
  }

  useEffect(() => {
    source.postype && source.isStoreConnected === null &&
      getStoreConnectivity(
        DEFAULT_CONNECTION_INFO[source.postype.toUpperCase()]
      )
        .then(({ data }) => data)
        .then((data: Connectivity) => {
          dispatch(updateIsStoreConnectedByImportCode({
            importCode: source.importcode,
            isStoreConnected: data.status
          }));
        });
    console.log("site card");
  }, []);

  return (
    <div
      className={
        isSelected
          ? cssClasses.selectedContainer
          : cssClasses.notSelectedContainer
      }
      onClick={onClick}
      // onClick={openSiteDetails}
    >
      <div className={cssClasses.elipse} onClick={handleClickOnElipse}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className={cssClasses.gasBrandLogoContainer}>
        <BrandBatch isCompetitor={source.isCompetitor} isStoreConnected={source.isStoreConnected} logoSrc={"img src"}/>
        {/* <img
          className={
            source.isCompetitor
              ? cssClasses.gasBrandLogoCompetitor
              : cssClasses.gasBrandLogoOwn
          }
          src={"logoSrc"}
          alt=""
        />
        <div className={source.isStoreConnected!==null ? (source.isStoreConnected ? cssClasses.storeOnline : cssClasses.storeOffline) : cssClasses.connectivityUnknown}></div> */}
      </div>
      <h5 className={cssClasses.siteName}>{source.displayname}</h5>
      <p
        className={cssClasses.price}
        onClick={(e: any) => {
          e.stopPropagation();
          console.log("price");
        }}
      >
        ${source.regularPrice}
      </p>
      {showElipsePopUp && (
        <>
          <div
            className={cssClasses.elipsePopUpBackdrop}
            onClick={(e: any) => {
              e.stopPropagation();
              setShowElipsePopUp(false);
            }}
          ></div>
          <div className={cssClasses.elipsePopUp}>
            <p
              onClick={(e) => {
                e.stopPropagation();
                alert("hello");
              }}
            >
              remove
            </p>
          </div>
        </>
      )}
      {/* <div className={cssClasses.icons}>icons</div> */}
    </div>
    // </div>
  );
}

export default SiteCard;
