import React, { useEffect, useState } from "react";
import cssClasses from "./IndividualSiteComponent.module.css";
import cross from "../../../../resources/cross.svg";
import { useDispatch } from "react-redux";
import { updateImportCode } from "../../../../reduxComponents/slices/importCodeSlice";
import { Source } from "../../../../dataDefinition/elasticSearchTypes";
import {
  getPrices,
  getStoreConnectivity,
  getStoreDetails,
  getTransactionFillAlert,
} from "../../../../services/externalCalls";
import axios from "axios";
import { GetStoreDetails } from "../../../../dataDefinition/getStoreDetailsTypes";
import { StoreConnectivityPayload } from "../../../../dataDefinition/dtoDataTypes";
import { PriceDetails } from "../../../../dataDefinition/getPricesTypes";
import PricesComponent from "./pricesComponent/PricesComponent";
import { DEFAULT_CONNECTION_INFO } from "../../../../utils/constants";
import BrandBatch from "../../../brandBatch/BrandBatch";
import { StoreDetails } from "../../../../reduxComponents/slices/mySitesSourcesSlice";
/**
 * TODO 1: call getStoreDetails to get importCode specific data
 * TODO 2: for the known POS types hardcode the payload for getStoreConnectivity call and if the POS
 *         is unknown then dont make the call
 */

type Props = {
  //   importCode: string;
  siteSource: StoreDetails;
};

function IndividualSiteComponent({ siteSource }: Props) {
  const dispatch = useDispatch();
  const [storeDetails, setStoreDetails] = useState<GetStoreDetails>(null);
  const [priceDetails, setPriceDetails] = useState<PriceDetails>(null);

  useEffect(() => {
    let source = axios.CancelToken.source();
    getStoreDetails(siteSource.importcode, source.token).then(({ data }) =>
      setStoreDetails(data)
    );
    const posType = siteSource.postype ?? null;
    console.log(posType);

    // const storeConnectivityPayload: StoreConnectivityPayload = {
    //   storeId: siteSource.importcode,
    //   posType: siteSource.postype,
    //   ipDnsName: siteSource.ipaddress,
    //   username: "",
    //   password: "",
    //   port: "",
    //   protocol: "",
    //   inBoundPath: "",
    //   outBoundPath: "",
    // };
    posType &&
      getStoreConnectivity(DEFAULT_CONNECTION_INFO[posType.toUpperCase()]).then(
        console.log
      );

    getPrices(siteSource.importcode).then(({ data }) => setPriceDetails(data));
    getTransactionFillAlert(siteSource.importcode).then(console.log);

    return () => {
      //cancelling the previous request to
      //get the previous site detail if new site card is
      //clicked before the previous response comes.
      source.cancel();
      setPriceDetails(null); /*when 2nd site card is clicked after 
      the 1st then old value was still 
      showing until the new value is populated, 
      that's why I am resetting the value on siteSource change*/
    };
  }, [siteSource]);

  function close() {
    dispatch(updateImportCode(""));
  }
  return (
    <div className={cssClasses.siteContainer}>
      <div className={cssClasses.individualSiteDetails}>
        <header className={cssClasses.individualSiteHeader}>
          <div className={cssClasses.close} onClick={close}>
            <img
              className={cssClasses.closeIcon}
              src={cross}
              alt={"cross"}
              height="14px"
            />
          </div>
          <div className={cssClasses.logoAndNameContainer}>
            <h3>{siteSource.displayname}</h3>
            <div className={cssClasses.gasBrandLogoContainer}>
              <BrandBatch
                isCompetitor={siteSource.isCompetitor}
                isStoreConnected={siteSource.isStoreConnected}
                logoSrc= {"src"}
              />

            </div>
          </div>
        </header>
        <main>
          <PricesComponent priceDetails={priceDetails} />
          <span>Price Sign</span>
          {siteSource?.priceSign ? (
            <div
              className={
                siteSource?.priceSign[0].online
                  ? cssClasses.priceSignOnlineIndicator
                  : cssClasses.priceSignOfflineIndicator
              }
            ></div>
          ) : (
            <div className={cssClasses.priceSignIndicator}></div>
          )}
        </main>
      </div>
    </div>
  );
}

export default IndividualSiteComponent;
