import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './slices/counterSlice'
import importCodeSlice from './slices/importCodeSlice';
import mySitesSources from './slices/mySitesSourcesSlice';

const store = configureStore({
  reducer: {
    counter: counterReducer,
    selectedImportCode: importCodeSlice,
    mySitesSources: mySitesSources
  },
  // enhancers: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
})

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch