import { useEffect, useState } from "react";
import { PieData } from "../../../dataDefinition/chartDataTypes";
import { ElasticSearchResult } from "../../../dataDefinition/elasticSearchTypes";
import { getBrandWiseAvgPrice } from "../../../services/externalCalls";
import { useSelector } from "react-redux";
import { RootState } from "../../../reduxComponents/store";
import AggregateComponent from "./aggregateComponent/AggregateComponent";
import IndividualSiteComponent from "./individualSiteComponent/IndividualSiteComponent";
import { useQuery } from "react-query";
import axios from "axios";

/**
 * TODO 1: use some cache or memoizing technique to reduce the getBrandWiseAvgPrice call
 */

type Props = {
  importCode?: string; //if import code is present then show data for individual site
};

function General({ importCode }: Props) {
  const [avgPerBrand, setAvgPerBrand] = useState<PieData[]>();
  const mySitesSources = useSelector(
    (state: RootState) => state.mySitesSources
  );
  // const { isLoading, data, isError, error } = useQuery(
  //   "Average-Price-Per-Brand",
  //   ()=> {
  //     console.log("useQuery");
      
  //     return getBrandWiseAvgPrice(mySitesSources.map(s=>s.importcode))
  //   }
  // );

  useEffect(() => {
    if (importCode) {
      //call getStoreDetails to get importCode specific data
    }

    mySitesSources && getBrandWiseAvgPrice(mySitesSources?.map(s=>s.importcode)).then(
      (data: ElasticSearchResult) => {
        const avgPerBrandTemp: PieData[] = [];
        data.aggregations.by_brand.buckets.map((b) => {
          avgPerBrandTemp.push({
            name: `${b.key},${b.avg_price_by_brandname.value.toFixed(3)}`,
            value: -(-b.doc_count),
          });
        });
        setAvgPerBrand(avgPerBrandTemp);
      }
    );    
  }, [importCode, mySitesSources]);
  
  // useEffect(()=>{
  //   const avgPerBrandTemp: PieData[] = [];
  //   data?.aggregations.by_brand.buckets.map((b) => {
  //     avgPerBrandTemp.push({
  //       name: `${b.key},${b.avg_price_by_brandname.value.toFixed(3)}`,
  //       value: -(-b.doc_count),
  //     });
  //   });
  //   setAvgPerBrand(avgPerBrandTemp);

  // },[data])

  return importCode === "" ? (
    <AggregateComponent
      avgPerBrand={avgPerBrand}
      mySitesSources={mySitesSources}
    />
  ) : (
    <IndividualSiteComponent
      siteSource={mySitesSources.filter((s) => s.importcode === importCode)[0]}
    />
  );
}

export default General;
