/**
 * Adds the google maps script tag with API key dynamically
 * @param callbackName
 * @returns
 */
export function loadGoogleMapsScript(callbackName = "initMap"): string {
  let googleMapsApiKey: string;
  try {
    const hostname = window && window.location && window.location.hostname;
    if (/^.*localhost.*/.test(hostname)) {
     // googleMapsApiKey = process.env.GOOGLE_MAPS_API_KEY;
    } else {
      const config = loadConfigSync();
      googleMapsApiKey = config.VISOPS_SECRET_google_api_key;
    }
    console.log("Google Maps API key loaded:", googleMapsApiKey);
  } catch (error) {
    console.error("Error loading configuration:", error);
  }
  if (!googleMapsApiKey) {
    console.error("Google Maps API Key is missing");
    return;
  }
  return googleMapsApiKey;

}

/**
 * Method fetched the keys from env json created by entrypoint.sh
 * @returns env.json
 */
function loadConfigSync() {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", "/assets/env.json", false); // false makes the request synchronous
  xhr.send(null);

  if (xhr.status === 200) {
    return JSON.parse(xhr.responseText);
  } else {
    throw new Error("Failed to load configuration file.");
  }
}
