import { useRef, useEffect, useState } from "react";
// import { styledMapproperties } from "./mapUtility";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
// import {
//   getLocationsData,
//   getLocationsDataDebounced,
// } from "../../utils/externalCalls";
import img from "../../resources/logos/711.png";
import {
  Bucket,
  ElasticLocation,
  Source,
} from "../../dataDefinition/elasticSearchTypes";
import { Bounds } from "../../dataDefinition/dtoDataTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reduxComponents/store";
import { updateImportCode } from "../../reduxComponents/slices/importCodeSlice";

//https://urldefense.com/v3/__https://www.youtube.com/watch?v=Xwcud1Qnnsw&t=101s__;!!GJsAII055wae8wY!e8WmYzVRcbC9f_Pmo6yd7NYtA9-BeJsvdlmWu-UU2BIcouhtmmM9BRttucoAH63Elvc2XURYOra6f8s7qwGyh2LenBp_$

const USA_CANADA_BOUNDS: Bounds = {
  north: 75.45,
  south: 5.9,
  west: -179.91,
  east: -38.02,
};

type Props = {
  center: google.maps.LatLngLiteral;
  zoom: number;
};

const lasVegas: google.maps.LatLngLiteral = { lat: 36.1716, lng: -115.1391 };

function MapComponent({ center, zoom }: Props) {
  //function MapComponent() {
  const ref = useRef<HTMLDivElement>(null);
  // const [map, setMap] = useState<google.maps.Map>(null);
  const markersRef = useRef<google.maps.Marker[]>(null);
  let placeSearchMarker: google.maps.Marker = null;
  const [activeImportCode, setActiveImportCode] = useState("");
  const mySitesSources = useSelector(
    (state: RootState) => state.mySitesSources
  );
  const dispatch = useDispatch();

  function convertElasticLocationToGoogleLocation(elasticLoc: ElasticLocation) {
    return {
      lat: elasticLoc.lat,
      lng: elasticLoc.lon,
    };
  }
  const createMarkers = (map: google.maps.Map, sources: Source[]) => {

    const markers = sources?.map((src, ind) => {
      const pos = convertElasticLocationToGoogleLocation(src.location);
      const marker = new google.maps.Marker({
        position: pos,
        map,
        optimized: true,
        title: src.displayname,
        label: {
          text: src.importcode,
        },
        // icon: {
        //   path: "M32.5111 32.5113C75.8593 -10.8369 146.141 -10.8369 189.489 32.5113C232.837 75.8595 232.837 146.141 189.489 189.489L118.071 260.907C114.166 264.812 107.834 264.812 103.929 260.907L32.5111 189.489C-10.837 146.141 -10.837 75.8595 32.5111 32.5113Z",
        //   fillColor: src.isCompetitor ? "orange" : "purple",
        //   fillOpacity: 1,
        //   strokeWeight: 1,
        //   strokeColor: "#ffffff",
        //   scale: 0.15,
        // },
      });

      // const infowindow = new google.maps.InfoWindow({
      //   content: "Site pop up " + ind,
      // });

      marker.addListener("click", () => {
        dispatch(updateImportCode(src.importcode));
        map.panTo(pos);
      });
      return marker;
    });
    return markers
  };

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current as HTMLElement, {
      center: center,
      zoom: zoom,
      //below are not mandatory
      fullscreenControl: false,
      restriction: {
        latLngBounds: USA_CANADA_BOUNDS,
        strictBounds: true,
      },
      mapTypeControl: false,
      streetViewControl: false,
    });
    // createMarkers(map, mySitesSources) //use this to create only marker
    new MarkerClusterer({ markers: createMarkers(map, mySitesSources), map });//use this to create google clusters
  }, [ref, mySitesSources]);

  return (
    <div
      ref={ref}
      id="map"
      style={{
        height: "300px",
        width: "500px",
        overflow: "hidden",
        borderRadius: "5px"
      }}
    />
  );
}

export default MapComponent;
