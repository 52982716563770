import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import cssClasses from "./FuelsMultiselectDropdown.module.css";

/** 
 * TODO 1: add props to add icon optionally.
 * TODO 2: add a placeholder text(when nothing is selected) optionally.
 * TODO 3: every dropdown option can have an icon
*/

type Props = {
  optionList: string[];
  handleDropdownChange: (selected: string[]) => void;
  label: string;
};

function FuelsMultiselectDropdown({
  optionList,
  handleDropdownChange,
  label,
}: Props) {
  const [options, setOptions] = useState([...optionList]);
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    getOptions(inputValue, setOptions);
  }, [inputValue]);

  useEffect(() => {
    handleDropdownChange(selected);
  }, [selected]);

  const getOptions = useCallback(
    debounce((text, setOptions) => {
      setOptions(optionList.filter((s) => s.toLowerCase().indexOf(text.toLowerCase()) >= 0));
    }, 200),
    []
  );

  function removeFromSelectedList(removeOption: string) {
    setSelected(() => {
      return [...selected.filter((s) => s !== removeOption)];
    });
  }

  function handleChange(e: any) {
    setInputValue(e.target.value);
  }

  function addToSelected(option: string) {
    console.log(option);
    if (!selected.includes(option)) {
      setSelected([...selected, option]);
    }
    setShowDropdown(false);
    setInputValue("");
  }

  return (
    <div className={cssClasses.container}>
      <label>{label}</label>
      <div className={cssClasses.itextInputContainer}>
        <input
          type="text"
          name="option"
          id="option"
          value={inputValue}
          onChange={handleChange}
          placeholder="type to filter"
          onFocus={() => setShowDropdown(true)}
          onBlur={() => setShowDropdown(false)}
          className={cssClasses.itextInput}
          autoComplete="off"
        />
        {inputValue ? (
          <p
            className={cssClasses.itextInputClear}
            onClick={() => setInputValue("")}
          >
            X
          </p>
        ) : <p className={cssClasses.itextInputClear}>
        &#9660;
      </p>}
      </div>

      <div className={cssClasses.selectedOptionContainer}>
        {selected.map((s) => {
          return (
            <span className={cssClasses.selectedOption} key={s}>
              {s}
              <span
                className={cssClasses.remove}
                onClick={() => {
                  removeFromSelectedList(s);
                }}
              >
                x
              </span>
            </span>
          );
        })}
      </div>
      {showDropdown && (
        <div className={cssClasses.optionsDropdown} id="optionDropdown">
          {options.map((option) => {
            return (
              <div
                onMouseDown={() => addToSelected(option)}
                className={cssClasses.optionList}
                key={option}
              >
                {option}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default FuelsMultiselectDropdown;
