import WebFont from "webfontloader";
import { useEffect } from "react";
import App from "./App";
import { Provider } from "react-redux";
import store from "./reduxComponents/store";
import { authenticate } from "./login";
import { getUserPrefenceByUserId, updateUserPreferenceFlag, updateUserPreferenceSites } from "./services/externalCalls";
// import { getData } from "./services/externalCalls";
// import { QueryClient, QueryClientProvider } from "react-query";

const dimention = ["General", "Price Update", "Transaction fill"];

export default function Root(props) {
  // const queryClient = new QueryClient();

  useEffect(() => {
    // getData().then(console.log)
    // authenticate()
    WebFont.load({
      google: {
        families: ["Poppins", "Chilanka"],
      },
    });
    console.log("environment", process.env.NODE_ENV);
    
  }, []);

  return (
    <Provider store={store}>
      {/* <QueryClientProvider client={queryClient}> */}
        <App />
      {/* </QueryClientProvider> */}
    </Provider>
  );
}
