// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uRxj7tqDYQ9zLEzVQB-FTQ\\=\\={\n    position: fixed;\n    inset: 0;\n    background-color: rgba(255, 0, 0, 0.395);\n    z-index: 5;\n}\n.jmJHzo3IfA6n9fEkxmVCsQ\\=\\={\n    position: absolute;\n    /* height: 200px;\n    width: 500px; */\n    top: 50%;\n    left: 50%;\n    z-index: 5;\n    transform: translate(-50%, -50%);\n    background-color: white;\n    border-radius: 5px;\n}", "",{"version":3,"sources":["webpack://./src/components/fuelsPopUp/FuelsPopUp.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,wCAAwC;IACxC,UAAU;AACd;AACA;IACI,kBAAkB;IAClB;mBACe;IACf,QAAQ;IACR,SAAS;IACT,UAAU;IACV,gCAAgC;IAChC,uBAAuB;IACvB,kBAAkB;AACtB","sourcesContent":[".popUpOverlay{\n    position: fixed;\n    inset: 0;\n    background-color: rgba(255, 0, 0, 0.395);\n    z-index: 5;\n}\n.popUpContainer{\n    position: absolute;\n    /* height: 200px;\n    width: 500px; */\n    top: 50%;\n    left: 50%;\n    z-index: 5;\n    transform: translate(-50%, -50%);\n    background-color: white;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popUpOverlay": "uRxj7tqDYQ9zLEzVQB-FTQ==",
	"popUpContainer": "jmJHzo3IfA6n9fEkxmVCsQ=="
};
export default ___CSS_LOADER_EXPORT___;
