// import { GEO_SEARCH_BY_IMPORT_CODE } from "../constants/QueryConstants";
// import axios from "axios";
// import { ES_AUTH_HEADER, GEO_LOCATION_ES_URL } from "../constants/URL";

import axios, { CancelToken } from "axios";
import { ES_AUTH_HEADER } from "./elasticConstants";
import { debounce } from "lodash";
import { ElasticSearchResult } from "../dataDefinition/elasticSearchTypes";
import { StoreConnectivityPayload } from "../dataDefinition/dtoDataTypes";
import env from '../assets/env.json'

// export const getLocationsDataDebounced = debounce(getLocationsData,300);

export const debouncedFunction = debounce(function (callback) {
  return callback();
}, 300);

export function getBrandWiseAvgPrice(mySites: string[]) {
  // console.log(mySites);

  const elasticQuery = JSON.stringify({
    size: 0,
    query: {
      bool: {
        must: [
          {
            terms: {
              importcode: mySites,
            },
          },
        ],
      },
    },
    aggs: {
      by_brand: {
        terms: {
          field: "brandname",
        },
        aggs: {
          avg_price_by_brandname: {
            avg: {
              field: "regularPrice",
            },
          },
        },
      },
    },
  });

  return getEsDataAsync(
    `https://${env.VISOPS_SECRET_apigee_host}/fuels/pricing/elasticsearch/geolocation/_search/?source_content_type=application/json`,
    elasticQuery
  );
}

export function getDetailsOfListOfStores(storeList: string[]) {
  console.log(env.VISOPS_SECRET_default_host);
  
  const elasticQuery = JSON.stringify({
    query: {
      bool: {
        must: [
          {
            terms: {
              importcode: storeList,
            },
          },
        ],
      },
    },
  });

  return getEsDataAsync(
    `https://${env.VISOPS_SECRET_apigee_host}/fuels/pricing/elasticsearch/geolocation/_search/?source_content_type=application/json`,
    elasticQuery
  );
}

export function getStoreDetails(storeId: string, cancelToken?: CancelToken) {
  return axios.get(
    `https://${env.VISOPS_SECRET_default_host}/fuels/pricing/fpcc-store-location-master/getStoreDetails?storeId=${storeId}`,
    {
      cancelToken: cancelToken,
    }
  );
}
export function getPrices(storeId: string, cancelToken?: CancelToken) {
  return axios.get(
    `https://${env.VISOPS_SECRET_default_host}/fuels/pricing/fpcc-store-location-util/locationBySiteId/${storeId}`,
    {
      cancelToken: cancelToken,
    }
  );
}
export function getTransactionFillAlert(
  storeId: string,
  cancelToken?: CancelToken
) {
  return axios.get(
    `https://${env.VISOPS_SECRET_default_host}/fuels/pricing/fpcc-store-location-util/transactionFillAlert?storeID=${storeId}`,
    {
      cancelToken: cancelToken,
    }
  );
}

export function getStoreConnectivity(
  data: StoreConnectivityPayload,
  cancelToken?: CancelToken
) {
  /**
   * TODO 1: remove the hardcoding
   */
  data = {
    storeId: "2403",
    posType: "Verifone",
    ipDnsName: "10.7.159.133",
    username: "Price",
    password: "hdm42hvqc",
    port: "443",
    protocol: "https",
    inBoundPath: "null",
    outBoundPath: "null",
  };
  return axios.post(
    `https://${env.VISOPS_SECRET_default_host}/fuels/pricing/fpcc-store-location-util/testDynamicStoreConnectivity`,
    data,
    {
      cancelToken: cancelToken,
    }
  );
}

function buildSearchQuery(queryText: string) {
  return {
    query: {
      bool: {
        should: [
          {
            wildcard: {
              importcode: {
                value: queryText + "*",
              },
            },
          },
          {
            wildcard: {
              displayname: {
                value: queryText + "*",
              },
            },
          },
        ],
      },
    },
  };
}

export const getEsDataAsync = async (url: string, query: string) => {
  const response = await axios.get(`${url}&source=${query}`, {
    headers: ES_AUTH_HEADER,
  });
  return response.data;
};

export const getImportCodeSearchResultAsync = async (query: string) => {
  console.log("In async123 " + query);

  const data = await getEsDataAsync(
    `https://${env.VISOPS_SECRET_apigee_host}/fuels/pricing/elasticsearch/geolocation/_search/?source_content_type=application/json`,
    JSON.stringify(buildSearchQuery(query))
  ).then((x) => {
    let queryResult: ElasticSearchResult = null;
    queryResult = x;
    return queryResult.hits.hits.map((h) => h._source);
  });

  return data;
};

export const getUserPrefenceByUserId = (userId: string) => {
  return axios.post(
    `https://webhooks.mongodb-realm.com/api/client/v2.0/app/fpcc_app-fplpg/service/userPrefernces/incoming_webhook/getSiteListByUserId`,
    {
      userId,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const updateUserPreferenceSites = (
  userId: string,
  updatedSitesList: string[]
) => {
  return axios.post(
    `https://webhooks.mongodb-realm.com/api/client/v2.0/app/fpcc_app-fplpg/service/userPrefernces/incoming_webhook/UpdateUserPreferenceSites`,
    {
      userId,
      updatedSitesList,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const updateUserPreferenceFlag = (
  userId: string,
  useUpdatedSites: boolean
) => {
  return axios.post(
    `https://webhooks.mongodb-realm.com/api/client/v2.0/app/fpcc_app-fplpg/service/userPrefernces/incoming_webhook/UpdateUserPreferenceFlag`,
    {
      userId,
      useUpdatedSites,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
