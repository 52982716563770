// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6I2ky1n0CN9febaDjFlEkg\\=\\={\n    /* background-color: rgb(245, 242, 255); */\n    /* border: 1px solid rgb(53, 46, 86); */\n    /* border-radius: 10px;\n    background: linear-gradient(45deg, rgb(217, 206, 255) 0%, rgb(196, 246, 255) 100%);\n    background: linear-gradient(45deg, #DFD9FA 0%, rgb(187, 230, 232) 100%);\n    border-radius: 5px;\n    padding: 10px; */\n}", "",{"version":3,"sources":["webpack://./src/components/fuelsEChats/fuelsPie/FuelsPie.module.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,uCAAuC;IACvC;;;;oBAIgB;AACpB","sourcesContent":[".container{\n    /* background-color: rgb(245, 242, 255); */\n    /* border: 1px solid rgb(53, 46, 86); */\n    /* border-radius: 10px;\n    background: linear-gradient(45deg, rgb(217, 206, 255) 0%, rgb(196, 246, 255) 100%);\n    background: linear-gradient(45deg, #DFD9FA 0%, rgb(187, 230, 232) 100%);\n    border-radius: 5px;\n    padding: 10px; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_6I2ky1n0CN9febaDjFlEkg=="
};
export default ___CSS_LOADER_EXPORT___;
