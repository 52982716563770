import React, { Children } from 'react'
import cssClasses from './FuelsPopUp.module.css'

/**TODO 1: take the position input as props
 * 
*/

type Props = {
    showPopUp: boolean;
    setShowPopUp: (showPopUp: boolean)=>void;
    children: JSX.Element;
}

function FuelsPopUp({showPopUp, setShowPopUp, children}: Props) {
  return (
    <>
    {
        showPopUp && <>
        <div className={cssClasses.popUpOverlay} onClick={()=>setShowPopUp(false)}></div>
        <div className={cssClasses.popUpContainer}>
            {children}
        </div>
        </>
      }
    </>
  )
}

export default FuelsPopUp