import React, { useRef, useState } from "react";
import cssClasses from "./ProfileContainerComponent.module.css";
import notificationIcon from "../../resources/notification.svg";

type Props = {
  userName: string;
};

function ProfileContainerComponent({ userName }: Props) {
  const [showNotificationDropdown, setShowNotificationDropdown] =
    useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  function showNotifications(e: any) {
    setShowNotificationDropdown(!showNotificationDropdown);
  }
  function showProfileDetails() {
    setShowProfileDropdown(!showProfileDropdown);
  }
  return (
    <div className={cssClasses.container}>
      <div>
        <img
          className={cssClasses.notificationIcon}
          src={notificationIcon}
          alt="notification"
          height="23px"
          onClick={showNotifications}
        />
        {showNotificationDropdown ? (
          <>
            <div className={cssClasses.notificationDropdownOverlay} onClick={()=>setShowNotificationDropdown(false)}></div>
            <div className={cssClasses.notificationDropdown}>notification</div>
          </>
        ) : null}
      </div>

      <div className={cssClasses.userName}>
        <h4
          onClick={showProfileDetails}
        >
          {userName}
        </h4>
        {showProfileDropdown ? (
          <>
            <div className={cssClasses.profileDropdownOverlay} onClick={()=>setShowProfileDropdown(false)}></div>
            <div className={cssClasses.profileDropdown}>profile</div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default ProfileContainerComponent;
