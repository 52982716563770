import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import { ElasticLocation, Pdisales, PriceSign, Source } from "../../dataDefinition/elasticSearchTypes";
import { RootState } from "../store";

export type StoreDetails = {
  importcode: string
  displayname: string
  iconid: string
  isCompetitor: boolean
  countryCode: string
  location: ElasticLocation
  brandname: string
  ipaddress?: string
  postype?: string
  regularPrice: number
  isdiesel: boolean
  pdisales: Pdisales
  priceSign?: PriceSign[]
  isStoreConnected: boolean
}

export function mapSourceToStoreDetails(source: Source){
  const storeDetails:StoreDetails  = {...source, isStoreConnected: null }
  return storeDetails;
}

const initialState: StoreDetails[] = null;

type AddANewSourceAction = {
  payload: Source;
  type: string;
};
type RemoveSourceByImportCodeAction = {
  payload: string;
  type: string;
};
type PopulateSourcesAction = {
  payload: Source[];
  type: string;
};

type UpdateSitePropertyByImportCodeAction = {
  payload: UpdateSitePropertyByImportCodePayload;
  type: string;
}

type UpdateSitePropertyByImportCodePayload = {
  importCode: string;
  isStoreConnected: boolean
}

export const mySitesSourcesSlice = createSlice({
  name: "mySitesSources",
  initialState: initialState,
  reducers: {
    addANewSource: (state, action: AddANewSourceAction) => [
      ...state,
      mapSourceToStoreDetails(action.payload),
    ],
    updateIsStoreConnectedByImportCode: (state, action: UpdateSitePropertyByImportCodeAction) => {
      console.log("updateSitePropertyByImportCode", action);
      const newstate = state.map( (s) => {
        if(s.importcode === action.payload.importCode){
          
          // s[action.payload.propertyName] = action.payload.propertyValue
          return {...s,
          isStoreConnected: action.payload.isStoreConnected};
        }
        return s;
      })
      // console.log(state)
      return [...newstate]
      },
    removeSourceByImportCode: (
      state,
      action: RemoveSourceByImportCodeAction
    ) => [...state.filter((s) => s.importcode != action.payload)],
    populateSources: (_, action: PopulateSourcesAction) => [
      ...action.payload.map(s=>mapSourceToStoreDetails(s)),
    ],
  },
});

// Action creators are generated for each case reducer function
export const { addANewSource, removeSourceByImportCode, populateSources, updateIsStoreConnectedByImportCode } =
  mySitesSourcesSlice.actions;

export default mySitesSourcesSlice.reducer;
