import React, { useState } from 'react'
import FuelsMultiselectDropdown from '../../fuelsMultiselectDropdown/FuelsMultiselectDropdown'
import cssClasses from './RemoveSitesPopUpContent.module.css'
type Props = {
  importCodes: string[];
}

function RemoveSitesPopUpContent({importCodes}: Props) {
  const [selectedSitesForRemoval, setSelectedSitesForRemoval] = useState([]);

  function handleRemoveAll(){
    //close the pop up here or pass a callbck to this component which closes the pop up
  }
  return (
    <div className={cssClasses.container}>
      <h4>Remove Multiple Sites</h4>
      <FuelsMultiselectDropdown optionList={importCodes} handleDropdownChange={setSelectedSitesForRemoval} label={''}/>
      <button disabled={selectedSitesForRemoval===[]} onClick={handleRemoveAll}>remove all</button>
    </div>
  )
}

export default RemoveSitesPopUpContent