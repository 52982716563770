// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Myhj3J6jqyUnB5FC-\\+Rv-w\\=\\={\n    padding: 10px;\n    width: 300px;\n}", "",{"version":3,"sources":["webpack://./src/components/mySitesComponent/removeSitesPopUpContent/RemoveSitesPopUpContent.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;AAChB","sourcesContent":[".container{\n    padding: 10px;\n    width: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Myhj3J6jqyUnB5FC-+Rv-w=="
};
export default ___CSS_LOADER_EXPORT___;
