import React from 'react'
import cssClasses from './BrandBatch.module.css'
type Props = {
    isCompetitor: boolean,
    isStoreConnected: boolean,
    logoSrc: string
}

function BrandBatch({isCompetitor, isStoreConnected, logoSrc}: Props) {
  return (
    <>
    <img
          className={
            isCompetitor
              ? cssClasses.gasBrandLogoCompetitor
              : cssClasses.gasBrandLogoOwn
          }
          src={logoSrc}
          alt=""
        />
        <div className={isStoreConnected!==null ? (isStoreConnected ? cssClasses.storeOnline : cssClasses.storeOffline) : cssClasses.connectivityUnknown}></div>
    </>
  )
}

export default BrandBatch