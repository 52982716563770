import React, { useEffect, useState } from "react";
import { StoreDetails } from "../../../../../reduxComponents/slices/mySitesSourcesSlice";
import FuelsExpandable from "../../../../fuelsExpandable/FuelsExpandable";
import cssClasses from "./PriceSign.module.css";
type Props = {
  mySitesSources: StoreDetails[];
};

function PriceSign({ mySitesSources }: Props) {
  const [onlinePriceSignStores, setOnlinePriceSignStores] = useState<
    StoreDetails[]
  >([]);
  const [offlinePriceSignStores, setOfflinePriceSignStores] = useState<
    StoreDetails[]
  >([]);
  const [noDataPriceSignStores, setNoDataPriceSignStores] = useState<
    StoreDetails[]
  >([]);

  useEffect(() => {
    const onlinePriceSignStoresTemp = [];
    const offlinePriceSignStoresTemp = [];
    const noDataPriceSignStoresTemp = [];
    mySitesSources?.forEach((s) => {
      if (s.priceSign == null || s.priceSign[0] == null) {
        noDataPriceSignStoresTemp.push(s);
        return;
      }

      if (s.priceSign[0].online) {
        onlinePriceSignStoresTemp.push(s);
        return;
      }

      offlinePriceSignStoresTemp.push(s);
    });

    setOnlinePriceSignStores(onlinePriceSignStoresTemp);
    setOfflinePriceSignStores(offlinePriceSignStoresTemp);
    setNoDataPriceSignStores(noDataPriceSignStoresTemp);
  }, [mySitesSources]);

  return (
    <div className={cssClasses.container}>
      <h4>Price Sign</h4>
      
      <FuelsExpandable
        headerText={`Online :${onlinePriceSignStores.length}`}
      ><>
      <h5>sites</h5>
        <div className={cssClasses.siteIds}>
            
            {
            onlinePriceSignStores.map((s)=><div key={s.importcode}>{s.importcode}</div>)
            
            }</div>
      </>
      </FuelsExpandable>
      <FuelsExpandable
        headerText={`Offline :${offlinePriceSignStores.length}`}
      ><>
      <h5>sites</h5>
        <div className={cssClasses.siteIds}>
            
            {
            offlinePriceSignStores.map((s)=><div key={s.importcode}>{s.importcode}</div>)
            
            }</div>
      </>
      </FuelsExpandable>
      <FuelsExpandable
        headerText={`Data Not Available :${noDataPriceSignStores.length}`}
      ><>
      <h5>sites</h5>
        <div className={cssClasses.siteIds}>
            
            {
            noDataPriceSignStores.map((s)=><div key={s.importcode}>{s.importcode}</div>)
            
            }</div>
      </>
      </FuelsExpandable>
    </div>
  );
}

export default PriceSign;
