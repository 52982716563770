// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6JRkRgpVy0cXdADeWzNtYw\\=\\={\n    border-radius: 10px;\n    background: linear-gradient(45deg, #DFD9FA 0%, rgb(187, 230, 232) 100%);\n    /* background: linear-gradient(45deg, #5846a4 0%, rgb(57, 114, 155) 100%); */\n    /* background-color: #c1f1ff; */\n    border-radius: 5px;\n    padding: 20px;\n    /* color: white; */\n}\n\n.gjtlJ3lFDc-yVYKiGulZSw\\=\\={\n    display: flex;\n    width: 200px;\n    flex-wrap: wrap;\n    flex-direction: row;\n}\n\n.gjtlJ3lFDc-yVYKiGulZSw\\=\\=>div{\n    margin: 5px;\n    width: 56px;\n    background-color: rgb(247, 247, 247);\n    text-align: center;\n    border-radius: 4px;\n    /* min-width: 0px; */\n}", "",{"version":3,"sources":["webpack://./src/components/dimentionalComponents/general/aggregateComponent/priceSign/PriceSign.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,uEAAuE;IACvE,4EAA4E;IAC5E,+BAA+B;IAC/B,kBAAkB;IAClB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,oCAAoC;IACpC,kBAAkB;IAClB,kBAAkB;IAClB,oBAAoB;AACxB","sourcesContent":[".container{\n    border-radius: 10px;\n    background: linear-gradient(45deg, #DFD9FA 0%, rgb(187, 230, 232) 100%);\n    /* background: linear-gradient(45deg, #5846a4 0%, rgb(57, 114, 155) 100%); */\n    /* background-color: #c1f1ff; */\n    border-radius: 5px;\n    padding: 20px;\n    /* color: white; */\n}\n\n.siteIds{\n    display: flex;\n    width: 200px;\n    flex-wrap: wrap;\n    flex-direction: row;\n}\n\n.siteIds>div{\n    margin: 5px;\n    width: 56px;\n    background-color: rgb(247, 247, 247);\n    text-align: center;\n    border-radius: 4px;\n    /* min-width: 0px; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_6JRkRgpVy0cXdADeWzNtYw==",
	"siteIds": "gjtlJ3lFDc-yVYKiGulZSw=="
};
export default ___CSS_LOADER_EXPORT___;
