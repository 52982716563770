import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Source } from "../../dataDefinition/elasticSearchTypes";
import { getImportCodeSearchResultAsync } from "../../services/externalCalls";
// import { Source } from "../../utils/dataDefinitions/searchResultTypes";
// import { getImportCodeSearchResultAsync } from "../../utils/externalCalls";
import cssClasses from "./FuelsSearch.module.css";
// import searchIcon from "./search.svg";

/**
 * TODO 1: this search is not working. once we click on a search result
 *         all other cluster and markers are getting removed.
 *
 */

type Props = {
  handleSearchResult: (selected) => void;
};

function FuelsSearch({ handleSearchResult }: Props) {
  const [options, setOptions] = useState<Source[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (inputValue != "") {
      getOptions(inputValue, setOptions);
    }
  }, [inputValue]);

  useEffect(() => {
    console.log(options);
  }, [options]);

  const getOptions = useCallback(
    debounce((text, setOptions) => {
      getImportCodeSearchResultAsync(text).then((src) => setOptions(src));
    }, 200),
    []
  );

  function handleChange(e: any) {
    console.log("type");

    setInputValue(e.target.value);
  }

  function selectFromSuggestion(option: Source) {
    setShowDropdown(false);
    setInputValue("");
    handleSearchResult(option);
  }

  function handleBlur() {
    setInputValue("");
    setShowDropdown(false);
  }

  return (
    <div className={cssClasses.container}>
      <div className={cssClasses.itextInputContainer}>
        <input
          type="text"
          name="option"
          id="option"
          value={inputValue}
          onChange={handleChange}
          placeholder="type to filter"
          onFocus={() => setShowDropdown(true)}
          onBlur={handleBlur}
          className={cssClasses.itextInput}
          autoComplete="off"
        />
        {inputValue ? (
          <p
            className={cssClasses.itextInputClear}
            onClick={() => setInputValue("")}
          >
            X
          </p>
        ) : null}
      </div>

      {showDropdown && (
        <div className={cssClasses.optionsDropdown} id="optionDropdown">
          {options.map((option) => {
            return (
              <div
                onMouseDown={() => selectFromSuggestion(option)}
                className={cssClasses.optionList}
                key={option.displayname}
                title="add this site to MySite"
              >
                <span>{option.displayname}</span><span>+</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default FuelsSearch;
