import { createSlice } from '@reduxjs/toolkit'

type UpdateImportCodeAction = {
  payload: string;
  type: string;
}

export const importCodeSlice = createSlice({
  name: 'selectedImportCode',
  initialState: "",
  reducers: {
    updateImportCode: (_, action:UpdateImportCodeAction) => action.payload,
  },
})

// Action creators are generated for each case reducer function
export const { updateImportCode } = importCodeSlice.actions

export default importCodeSlice.reducer