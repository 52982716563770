import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Source } from "../../dataDefinition/elasticSearchTypes";
import { updateImportCode } from "../../reduxComponents/slices/importCodeSlice";
import { StoreDetails } from "../../reduxComponents/slices/mySitesSourcesSlice";
import { RootState } from "../../reduxComponents/store";
import FuelsChips from "../fuelsChips/FuelsChips";
import FuelsNavigation from "../fuelsNavigation/FuelsNavigation";
import FuelsPopUp from "../fuelsPopUp/FuelsPopUp";
import FuelsSearch from "../fuelsSearch/FuelsSearch";
// import AddSitePopUp from "./addSitePopUp/AddSitePopUp";
import cssClasses from "./MySitesComponent.module.css";
import RemoveSitesPopUpContent from "./removeSitesPopUpContent/RemoveSitesPopUpContent";
import SiteCard from "./siteCard/SiteCard";

/**
 * TODO 1: add filters (expandable) below the search box, this filter 
 *         will be a stored in global state as a function, and that 
 *         function can be set either from the filter menu in this 
 *         component or from any chart in aggregate component 
 */

type Props = {
  mySitesSources: StoreDetails[];
};

function MySitesComponent({ mySitesSources }: Props) {
  const selectedImportCode = useSelector(
    (s: RootState) => s.selectedImportCode
  );
  const dispatch = useDispatch();
  const [rangeOfCards, setRangeOfCards] = useState([]);
  const [showRemoveSitePopUp, setShowRemoveSitePopUp] = useState(false);

  return (
    <div className={cssClasses.container}>
      <div className={cssClasses.header}>
        <h3>My&nbsp;Sites</h3>
        {/* <span
          className={cssClasses.addToMySites}
          tabIndex={0}
          onClick={handleAddSitePopup}
        >
          +
        </span> */}
      </div>
      {/* <FuelsChips
        filterChips={["All", "Own", "Competetor"]}
        handleClick={console.log}
      /> */}
      <div className={cssClasses.siteSearch}>
        <p className={cssClasses.siteSearchLabel}>Add site to My&nbsp;Sites</p>
        <FuelsSearch handleSearchResult={console.log} />
      </div>
      <button
        className={cssClasses.removeSites}
        onClick={() => setShowRemoveSitePopUp(true)}
      >
        remove sites
      </button>
      <div className={cssClasses.siteCardsContainer}>
        {mySitesSources
          ?.slice(rangeOfCards[0], rangeOfCards[1] + 1)
          .map((s) => (
            <SiteCard
              key={s.importcode}
              source={s}
              isSelected={selectedImportCode === s.importcode ? true : false}
              onClick={() => dispatch(updateImportCode(s.importcode))}
            />
          ))}
      </div>
      <div className={cssClasses.navigationContainer}>
        <FuelsNavigation
          size={mySitesSources?.length}
          showInAPage={5}
          currentRange={(x, y) => {
            console.log(x, y);

            setRangeOfCards([x, y]);
          }}
        />
      </div>
      {/* {
        showRemoveSitePopUp && <>
        <div className={cssClasses.removeSitePopUpOverlay} onClick={()=>setShowRemoveSitePopUp(false)}></div>
        <div className={cssClasses.removeSitePopUp}></div>
        </>
      } */}

      <FuelsPopUp
        showPopUp={showRemoveSitePopUp}
        setShowPopUp={setShowRemoveSitePopUp}
      >
        <RemoveSitesPopUpContent
          importCodes={mySitesSources?.map((s) => s.importcode)}
        />
      </FuelsPopUp>
    </div>
  );
}

export default MySitesComponent;
