// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LjKbxMLJyvzaGOj7GOctcw\\=\\= {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.V5Lv5k5yDuysrE-CXgtKzQ\\=\\= {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n}\n._4cixh3WYFIihcVh9Qj7RZQ\\=\\= {\n  display: flex;\n  flex-direction: column;\n  min-width: 4.2rem;\n}\n\n.qRrGZvVxaSpFyvYhmcLfAw\\=\\= {\n  background-color: rgb(58, 60, 80);\n  color: white;\n  height: 28px;\n  line-height: 28px;\n  text-align: center;\n  border-radius: 4px;\n}\n\n.hdRkIT1-1BqQvl3kszXyCw\\=\\= {\n  font-size: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/dimentionalComponents/general/individualSiteComponent/pricesComponent/PricesComponent.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,iCAAiC;EACjC,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.singleSourcePricesContainer {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n}\n.onePriceContainer {\n  display: flex;\n  flex-direction: column;\n  min-width: 4.2rem;\n}\n\n.price {\n  background-color: rgb(58, 60, 80);\n  color: white;\n  height: 28px;\n  line-height: 28px;\n  text-align: center;\n  border-radius: 4px;\n}\n\n.dateTime {\n  font-size: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LjKbxMLJyvzaGOj7GOctcw==",
	"singleSourcePricesContainer": "V5Lv5k5yDuysrE-CXgtKzQ==",
	"onePriceContainer": "_4cixh3WYFIihcVh9Qj7RZQ==",
	"price": "qRrGZvVxaSpFyvYhmcLfAw==",
	"dateTime": "hdRkIT1-1BqQvl3kszXyCw=="
};
export default ___CSS_LOADER_EXPORT___;
